<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll drone-table"
		size="small"
		:pagination="pagination"
		:row-selection="rowSelection"
		v-on="$listeners">
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="drone.name" :sorter="sort"
			:title="$t('drone.field.name')"
			data-index="drone.name"
			:width="150">
			<template v-slot="text,record">
				<my-router-link :clickable="linkClickable" name="drone/view" :param-value="record.drone.serial_no" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="drone.serialNo"
			:title="$t('drone.field.serial_no')"
			data-index="drone.serialNo"
    	:sorter="sort"
			:width="135">
			<template v-slot="text,record">
				<my-router-link v-if="record.drone" :clickable="linkClickable" name="drone/view" :param-value="record.drone.serialNo" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<!-- <a-table-column
			v-if="showPixhawk"
			key="pixhawk_serial_no" :sorter="true"
			:title="$t('drone.field.pixhawk_serial_no')"
			data-index="drone.pixhawk_serial_no"
			:width="200">
			<template v-slot="text,record">
				<my-router-link v-if="record.drone" :clickable="linkClickable" name="drone/view" :param-value="record.drone.serialNo" param-name="serial_no">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column> -->
		<a-table-column
			v-if="!hideModel" key="droneModel.name"
			:sorter="sort"
			:title="$t('drone.field.drone_model_id')"
			data-index="droneModel"
			:width="150">
			<template v-slot="droneModel">
				<my-router-link v-if="droneModel" :clickable="linkClickable" name="drone_model/view" :param-value="droneModel.id" auth-action="read" auth-module="droneModel">
					{{droneModel.name}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideLot" key="droneLot.lotName"
			:sorter="sort"
			:title="$t('drone.field.drone_lot_id')"
			data-index="droneLot"
			:width="150">
			<template v-slot="droneLot">
				<my-router-link  v-if="droneLot" :clickable="linkClickable" name="drone_lot/view" :param-value="droneLot.id">
					{{droneLot.lotName}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="droneOwner.companyId.name" :sorter="sort"
			:title="$t('drone.field.company_name')"
			data-index="droneOwner"
			:width="150">
			<template v-slot="droneOwner">
				<my-router-link v-if="droneOwner.companyId" :clickable="linkClickable" name="company/view" :param-value="droneOwner.companyId.id" auth-action="read" auth-module="company">
					{{droneOwner.companyId.name}}
				</my-router-link>
			</template>
		</a-table-column>

		<a-table-column
			key="drone.warrantyStartDate" :sorter="sortByDate"
			:title="$t('drone.field.warranty_start_date')"
			data-index="drone"
			align="center"
			:width="150">
			<template v-slot="drone">
				<span>{{drone.warrantyStartDate | date('LL','-')}}</span>
			</template>
		</a-table-column>

		<a-table-column
			key="droneOwner.customerId.name" :sorter="sort"
			:title="$t('drone.field.customer')"
			data-index="droneOwner.customerId.name"/>
		<a-table-column
			v-if="!hideFirmware"
			key="firmware" :sorter="true"
			:title="$t('drone.field.firmware')"
			:width="100"
			align="center"
			data-index="droneOwner.firmwareId.name"/>
		<a-table-column
			v-if="!hidePhone"
			key="droneOwner.customerId.phone" :sorter="true"
			:title="$t('drone.field.phone')"
			:width="100"
			align="center"
			data-index="droneOwner.customerId.phone"/>
		<a-table-column
			v-if="!hideEmail"
			key="droneOwner.customerId.email" :sorter="true"
			:title="$t('drone.field.email')"
			align="center"
			data-index="droneOwner.customerId.email"/>
		<a-table-column
			v-if="!hideStatus"
			key="drone.status" :sorter="sort"
			:title="$t('drone.field.status')"
			data-index="drone.status"
			align="center"
			:width="150">
			<template v-slot="text">
				<DroneStatus :status="text" />
			</template>
		</a-table-column>
		<a-table-column
			v-if="canRegister && !hideRegister"
			class="mytable-action-col"
			:width="50"
			align="center">
			<template v-slot="{ drone }">
				<my-router-link v-if="isShowRegister(drone)" name="drone/register" :param-value="drone.serialNo" param-name="serial_no">
					<a-icon type="file-add" />
				</my-router-link>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import DroneStatus from "@components/drone/DroneStatus.vue"

export default {
	components : {
		"a-table" : Table, "a-table-column" : Table.Column,
		DroneStatus
	},
	props : {
		pagination : {
			type : null,
			default : () => []
		},
		hideLot : {
			type : Boolean,
			default : false
		},
		hideModel : {
			type : Boolean,
			default : false
		},
		showPixhawk : {
			type : Boolean,
			default : false,
		},
		hideRegister: {
			type: Boolean,
			default: false
		},
		hideStatus: {
			type: Boolean,
			default: false
		},
		hideFirmware: {
			type: Boolean,
			default: false
		},
		hidePhone: {
			type: Boolean,
			default: true
		},
		hideEmail: {
			type: Boolean,
			default: true
		},
		isSelectable: {
			type: Boolean,
			default: false
		},
		linkClickable: {
			type: Boolean,
			default: true
		},
		columnAction: {
			type: Object,
			default: undefined
		}
	},
	data() {
    return {
      selectedRowKeys: [], // Check here to configure the default column
    };
  },
	computed : {
		canRegister() {
			return this.$authorize('register','drone')
		},
		rowSelection() {
			if(this.isSelectable) {
				return {
					type: 'radio',
					selectedRowKeys: this.selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						this.selectedRowKeys = [...selectedRowKeys]
						this.$emit('change-drone', selectedRows[selectedRowKeys[0]]?.drone?.serialNo);
					},
					getCheckboxProps: record => {
						this.clearSelected()
						return {
							props:{
								name: 'droneTable',
							}
						}
					},
				};
			}
			return undefined;
		}
	},
	methods : {
		isShowRegister(drone) {
			return this.canRegister && drone.status === 'new'
		},
		clearSelected(){
			this.selectedRowKeys = []
		},
		sort(a, b) {
			const keys = this.columnAction.columnKey ? this.columnAction.columnKey.split('.') : ['drone', 'serialNo']
			const length = keys.length
			const fieldA = (length === 2 ? a[keys[0]]?.[keys[1]] : a[keys[0]]?.[keys[1]]?.[keys[2]]) || ''
			const fieldB = (length === 2 ? b[keys[0]]?.[keys[1]] : b[keys[0]]?.[keys[1]]?.[keys[2]]) || ''
			return fieldA?.localeCompare(fieldB)
		},
		sortByDate(a, b) {
			return new Date(a.drone.warrantyStartDate) - new Date(b.drone.warrantyStartDate);
		}
	}
}
</script>

<style lang="less" scoped>
.drone-table::v-deep table {
	min-width : 1200px;
}
</style>
